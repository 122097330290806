import React, { useState } from 'react';
import formatMoney from '../../../utils/formatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import paymentApi from '../../../api/paymentApi';

const CancellPayment = ({
    setOpenModal,
    payment,
    setPayment,
    fetchPayment,
}) => {
    const [note, setNote] = useState('');
    const handleCloseModal = () => {
        setOpenModal(false);
        setNote('');
    };

    const handleAddNote = (e) => {
        setNote(e.currentTarget.value);
    };

    const handleCancellPayment = async (id) => {
        if (!note) return;
        try {
            await paymentApi.cancellPayment(id, { note });
            fetchPayment();
            handleCloseModal();
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="bg-white p-4 flex flex-col gap-6">
            <p>Huỷ yêu cầu rút tiền</p>
            {payment && (
                <>
                    {' '}
                    <div className="flex flex-col min-w-[400px]">
                        <p className="font-semibold">Thông tin chi tiết:</p>
                        <div className="flex justify-between">
                            <span>Mã yêu cầu:</span>
                            <span className="underline">
                                #{payment.paymentId}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span>User ID:</span>
                            <span>#{payment.userId}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Chủ tài khoản:</span>
                            <span>{payment.bankAccountName}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Số tài khoản:</span>
                            <span>{payment.bankAccountNumber}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Số tiền:</span>
                            <span className="text-pink-600">
                                {formatMoney.format(payment.amount)}
                            </span>
                        </div>
                    </div>
                    <div className="border-t">
                        <p className="py-2">Lý do huỷ</p>
                        <input
                            value={note}
                            onChange={handleAddNote}
                            type="text"
                            placeholder="Ghi chú"
                            className="p-3 bg-slate-200 w-full rounded-lg"
                        />
                    </div>
                    <div className="flex justify-between gap-3">
                        <button
                            onClick={() =>
                                handleCancellPayment(payment.paymentId)
                            }
                            disabled={!note}
                            className="p-2 w-full rounded-lg border disabled:opacity-60 disabled:cursor-not-allowed"
                        >
                            Huỷ yêu cầu
                        </button>
                        <button
                            onClick={handleCloseModal}
                            className="p-2 w-full rounded-lg bg-pink-600 text-white"
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CancellPayment;
