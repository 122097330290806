import { DatePicker, Select } from 'antd';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';
import React from 'react';

function Filter({ filter, setFilter }) {
    const statusOptions = [
        {
            label: 'Chờ duyệt',
            value: 1,
        },
        {
            label: 'Đã duyệt',
            value: 2,
        },
        {
            label: 'Đã huỷ',
            value: 3,
        },
    ];

    const onSelectStatus = (value) => {
        setFilter((prev) => ({ ...prev, status: value }));
    };

    const onSelectDate = (value) => {
        const startDate = value
            ? getUnixTime(startOfDay(value[0]['$d']))
            : undefined;
        const endDate = value
            ? getUnixTime(endOfDay(value[1]['$d']))
            : undefined;
        setFilter((prev) => ({ ...prev, startDate, endDate }));
    };
    return (
        <div className="flex gap-2">
            <Select
                onChange={onSelectStatus}
                options={statusOptions}
                className="w-40"
                allowClear
                placeholder="Trạng thái"
                value={filter.status}
            />
            <div>
                <DatePicker.RangePicker
                    onChange={onSelectDate}
                    placeholder={['Từ ngày', 'Đến ngày']}
                    allowClear
                />
            </div>
        </div>
    );
}

export default Filter;
