import React from 'react';
import { Link } from 'react-router-dom';

function AuthHeader() {
    return (
        <div className="flex justify-between items-center w-full h-16 py-3">
            <Link
                to={'/'}
                className="font-semibold h-full hover:bg-slate-200 rounded-lg"
            >
                <img
                    className="h-full object-cover"
                    src="./img/brand-logo/logo-chietkhaupro-500x160.svg"
                    alt="logo-chietkhaupro"
                />
            </Link>
        </div>
    );
}

export default AuthHeader;
