import { faCircleNotch, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function YesNo({ title, onClose, content, onSubmit, isLoading }) {
    const handleClose = () => {
        if (!onClose) return;
        onClose();
    };
    const handleSubmit = () => {
        if (!onSubmit) return;
        onSubmit();
    };
    return (
        <div className="max-w-[720px] min-h-[200px] bg-white p-6 flex flex-col justify-between">
            <h3 className="text-center font-semibold">
                {title || 'Bạn đang thực hiện thao tác'}
            </h3>
            {content || (
                <div className=" flex flex-col justify-center items-center">
                    <FontAwesomeIcon
                        className="text-2xl text-orange-600"
                        icon={faWarning}
                    />
                    <p>Vui lòng kiểm tra kỹ thông tin trước khi xác nhận</p>
                </div>
            )}
            <div className="flex justify-between items-center gap-3">
                <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="p-2 w-full border rounded-md disabled:text-gray-400"
                >
                    {isLoading ? (
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            className="animate-spin"
                        />
                    ) : (
                        'Xác nhận'
                    )}
                </button>
                <button
                    onClick={handleClose}
                    className="p-2 w-full bg-pink-600 text-white rounded-md"
                >
                    Huỷ
                </button>
            </div>
        </div>
    );
}

export default YesNo;
