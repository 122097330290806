import axiosClient from './axiosClient';

const gameApi = {
    getGameDashBoard() {
        const baseURL = 'game/game_dashboard';
        return axiosClient.post(baseURL);
    },
    getGameDetail(gameId) {
        const baseURL = 'game/get_details';
        return axiosClient.post(baseURL, { gameId });
    },
    updateStatusGame(gameId, newStatus) {
        const baseURL = 'game/update_game';
        return axiosClient.post(baseURL, { gameId, newStatus });
    },
    gameResult(gameId) {
        const baseURL = 'game/game_results';
        return axiosClient.post(baseURL, { gameId });
    },
    startQuestion(questionId) {
        const baseURL = 'game/update_question';
        return axiosClient.post(baseURL, { questionId, status: 2 });
    },
    endQuestion(questionId) {
        const baseURL = 'game/end_question';
        return axiosClient.post(baseURL, { questionId });
    },
    getAllUser(query) {
        const baseURL = `game/get_all_minigame_user?${
            query || ''
        }&sort=-coin&coin[gt]=0`;
        return axiosClient.get(baseURL);
    },
    resetUserCoin(formData) {
        const baseURL = `game/reset_user_coin`;
        return axiosClient.post(baseURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    addNote(userId, note) {
        const baseURL = `game/add_note`;
        return axiosClient.post(baseURL, { userId, note });
    },
    getPendingCoin() {
        const baseURL = `game/pending_paid`;
        return axiosClient.get(baseURL);
    },
    changePendingToSuccessCoid(formData) {
        const baseURL = 'game/update_pending_to_success_paidcoin';
        return axiosClient.post(baseURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

export default gameApi;
