const convertStatus = (status: number) => {
    const statusConverted = {
        label: 'pending',
        style: 'bg-orange-100 text-orange-600',
    };

    switch (status) {
        case 1:
            statusConverted.label = 'pending';
            statusConverted.style = 'bg-orange-100 text-orange-600';
            break;
        case 2:
            statusConverted.label = 'completed';
            statusConverted.style = 'bg-green-100 text-green-600';
            break;
        case 3:
            statusConverted.label = 'cancelled';
            statusConverted.style = 'bg-gray-200 text-gray-600';
            break;

        default:
            break;
    }

    return statusConverted;
};

export default convertStatus;
