import React, { useEffect, useState } from 'react';
import formatMoney from '../../../utils/formatMoney';
import gameApi from '../../../api/gameApi';
import { Table } from 'antd';
import { format } from 'date-fns';
import Modal from '../../../components/Modal';
import UpdatePaidCoin from './UpdatePaidCoin';

function PendingCoin() {
    const [pendings, setPendings] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [paidInfo, setPaidInfo] = useState();
    const dataSource = pendings.map((el, index) => ({
        key: el.id,
        id: el.id,
        createTime: el.createTime,
        status: el.status,
        coin: el.coin,
        user: el.user,
        note: el.note,
    }));

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createTime',
            key: 'createTime',
            render(_, { createTime }) {
                return format(createTime, 'dd/MM/yyyy HH:mm');
            },
        },
        {
            title: 'Số tiền',
            dataIndex: 'coin',
            key: 'coin',
            render(_, { coin }) {
                return formatMoney.format(coin);
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Thao tác',
            render(_, data) {
                return (
                    <div>
                        <button
                            onClick={() => {
                                setIsOpen(true);
                                setPaidInfo(data);
                            }}
                            className="bg-pink-600 text-white p-1 rounded-md"
                        >
                            Thử lại
                        </button>
                    </div>
                );
            },
        },
    ];

    const fetchPendingCoin = async () => {
        try {
            const { data } = await gameApi.getPendingCoin();
            setPendings(data.pendingPaid);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchPendingCoin();
    }, []);
    return (
        <div className="w-full p-4">
            <div className="w-full overflow-auto">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
            </div>
            <Modal isOpen={isOpen}>
                <UpdatePaidCoin
                    paidInfo={paidInfo}
                    onClose={() => setIsOpen(false)}
                    reloadPaidCoins={fetchPendingCoin}
                />
            </Modal>
        </div>
    );
}

export default PendingCoin;
