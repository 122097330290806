import React, { useEffect, useState } from 'react';
import authApi from '../../../api/authApi';
import { DatePicker, Input, Pagination, Select, Table } from 'antd';
import {
    endOfDay,
    format,
    fromUnixTime,
    getUnixTime,
    startOfDay,
} from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import formatMoney from '../../../utils/formatMoney';

function Order() {
    const [orders, setOrders] = useState([]);
    const [totalOrder, setTotalOrde] = useState(0);

    const [filter, setFilter] = useState({
        page: 1,
        limit: 20,
        userId: '',
        orderId: '',
        conversionStatus: undefined,
        campId: undefined,
        clickTime: {
            startDate: undefined,
            endDate: undefined,
        },
    });

    const statusOptions = [
        {
            label: 'Chờ duyệt',
            value: 1,
        },
        {
            label: 'Đã duyệt',
            value: 2,
        },
        {
            label: 'Đã huỷ',
            value: 3,
        },
    ];

    const campaignOptions = [
        {
            label: 'Shopee',
            value: 'shopee',
        },
        {
            label: 'Lazada',
            value: 'lazada',
        },
        {
            label: 'TikTok',
            value: 'tiktok',
        },
    ];

    const dataSource = orders.map((el, index) => ({
        key: index,
        id: el.orderId,
        status: el.conversionStatus,
        clickTime: el.clickTime,
        payTime: el.payTime,
        userId: el.userId,
        payedToUser: el.payedToUser,
        campId: el.campId || '-',
        orderStatus: el.orderStatus,
        commission: el.commission,
        userCommission: el.userCommission,
    }));

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nguồn',
            dataIndex: 'campId',
            key: 'campId',
        },
        {
            title: 'Order status',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render(_, { status }) {
                switch (status) {
                    case 1:
                        return (
                            <span className="text-sm px-1 whitespace-nowrap rounded border-orange-600 text-orange-600 bg-orange-50">
                                Chờ duyệt
                            </span>
                        );
                    case 2:
                        return (
                            <span className="text-sm px-1 whitespace-nowrap rounded border-green-600 text-green-600 bg-green-50">
                                Đã duyệt
                            </span>
                        );
                    case 3:
                        return (
                            <span className="text-sm px-1 whitespace-nowrap rounded border-gray-600 text-gray-600 bg-gray-100">
                                Đã huỷ
                            </span>
                        );
                    case 4:
                        return (
                            <span className="text-sm px-1 whitespace-nowrap rounded border-gray-600 text-gray-600 bg-gray-100">
                                Chờ duyệt
                            </span>
                        );
                    default:
                        break;
                }
            },
        },
        {
            title: 'Hoa hồng',
            dataIndex: 'commission',
            key: 'commission',
            render(_, { commission }) {
                return formatMoney.format(commission * 1);
            },
        },

        {
            title: '% user',
            dataIndex: 'userCommission',
            key: 'userCommission',
            render(_, { userCommission }) {
                return formatMoney.format(userCommission * 1);
            },
        },

        {
            title: 'Ngày tạo',
            dataIndex: 'clickTime',
            key: 'clickTime',
            render(_, { clickTime }) {
                return format(
                    new Date(fromUnixTime(clickTime)),
                    'dd/MM/yyyy HH:mm:ss'
                );
            },
        },
        {
            title: 'Thanh toán',
            dataIndex: 'payedToUser',
            key: 'payedToUser',
            render(_, { payedToUser }) {
                switch (payedToUser) {
                    case true:
                        return <FontAwesomeIcon icon={faCheckCircle} />;
                    default:
                        break;
                }
            },
        },
        {
            title: 'Ngày TT',
            dataIndex: 'payTime',
            key: 'payTime',
            render(_, { payTime }) {
                if (!payTime) return;
                return format(
                    new Date(fromUnixTime(payTime)),
                    'dd/MM/yyyy HH:mm:ss'
                );
            },
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
        },
    ];

    const onChangeOrderId = (e) => {
        setFilter((prev) => ({ ...prev, orderId: e.target.value }));
    };

    const onChangeUserId = (e) => {
        setFilter((prev) => ({ ...prev, userId: e.target.value }));
    };

    const onStatus = (value) => {
        setFilter((prev) => ({ ...prev, conversionStatus: value }));
    };
    const onCampaign = (value) => {
        setFilter((prev) => ({ ...prev, campId: value }));
    };

    const onSelectDate = (value) => {
        if (!value)
            return setFilter((prev) => ({
                ...prev,
                clickTime: {
                    endDate: undefined,
                    startDate: undefined,
                },
            }));
        const startDate = getUnixTime(startOfDay(value[0]['$d']));
        const endDate = getUnixTime(endOfDay(value[1]['$d']));
        setFilter((prev) => ({
            ...prev,
            clickTime: {
                endDate,
                startDate,
            },
        }));
    };

    const onSetPage = (page, limit) => {
        setFilter((prev) => ({ ...prev, page, limit }));
    };

    useEffect(() => {
        const fetchOrders = async () => {
            const queryString = Object.keys(filter)
                .reduce((acc, cur) => {
                    if (cur === 'clickTime') {
                        if (!filter[cur].startDate || !filter[cur].endDate)
                            return acc;
                        acc.push(
                            `clickTime[gte]=${filter[cur].startDate}&clickTime[lte]=${filter[cur].endDate}`
                        );
                        return acc;
                    }
                    if (!filter[cur]) return acc;

                    acc.push(`${cur}=${filter[cur]}`);
                    return acc;
                }, [])
                .join('&');

            console.log(queryString);

            try {
                const { data } = await authApi.getAllOrder(
                    'sort=-clickTime&' + queryString || 'sort=-clickTime'
                );
                setOrders(data.allOrder);
                setTotalOrde(data.totalOrder);
            } catch (error) {
                console.log(error);
            }
        };
        fetchOrders();
    }, [filter]);
    return (
        <div className="p-4 w-full flex flex-col gap-2">
            <p>Danh sách đơn hàng</p>
            <div className="flex gap-2">
                <Input
                    placeholder="Mã đơn hàng"
                    className="w-60"
                    onChange={onChangeOrderId}
                />
                <Input
                    placeholder="User ID"
                    className="w-40"
                    onChange={onChangeUserId}
                />
                <Select
                    onChange={onStatus}
                    allowClear
                    options={statusOptions}
                    placeholder="Trạng thái"
                />
                <Select
                    onChange={onCampaign}
                    allowClear
                    options={campaignOptions}
                    placeholder="Nguồn"
                />
                <DatePicker.RangePicker
                    onChange={onSelectDate}
                    placeholder={['Từ ngày', 'Đến ngày']}
                />
            </div>
            <div>
                <p>hello</p>
            </div>
            <div className="w-full overflow-auto">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
            </div>
            <div className="flex justify-end">
                <Pagination
                    onChange={onSetPage}
                    total={totalOrder}
                    defaultPageSize={filter.limit}
                    showSizeChanger
                    pageSizeOptions={[20, 50, 100, 200, 500]}
                />
            </div>
        </div>
    );
}

export default Order;
