import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Auth/Login/Login';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { getUserInfoByToken } from './store/authSlice';
import protectUser from './utils/loader/protectUser';
import PaymentDetail from './pages/Home/Payment/PaymentDetail';
import { ShopeeLive } from './pages/ShopeeLive';
import User from './pages/Home/User';
import TopUser from './pages/Home/TopUser/TopUser';
import Order from './pages/Home/Order/Order';
import Commission from './pages/Home/Commission/Commission';
import DashBoard from './pages/Home/Dashboard/DashBoard';
import Bonus from './pages/Home/Bonus/Bonus';
import Payment from './pages/Home/Payment/Payment';
import Game from './pages/Game/Game';
import GameDetail from './pages/Game/GameDetail';
import GameCoin from './pages/Home/GameCoin/GameCoin';
import PendingCoin from './pages/Home/GameCoin/PendingCoin';
function App() {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getUserInfoByToken());
    }, [dispatch]);
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Home />,
            loader: protectUser,
            children: [
                {
                    path: '/user',
                    element: <User />,
                },
                {
                    path: '/topuser',
                    element: <TopUser />,
                },
                {
                    path: '/order',
                    element: <Order />,
                },
                {
                    path: '/commission',
                    element: <Commission />,
                },
                {
                    path: '/bonus',
                    element: <Bonus />,
                },
                {
                    index: true,
                    path: '/dashboard',
                    element: <DashBoard />,
                },
                {
                    path: 'payment',
                    element: <Payment />,
                },
                {
                    path: 'payment/:id',
                    element: <PaymentDetail />,
                },
                {
                    path: 'gamecoin',
                    element: <GameCoin />,
                },
                {
                    path: 'pendingcoin',
                    element: <PendingCoin />,
                },
            ],
        },
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/shopeelive',
            element: <ShopeeLive />,
        },
        {
            path: '/game_manager',
            element: <Game />,
        },
        {
            path: '/game_manager/:gameId',
            element: <GameDetail />,
        },
    ]);
    return <RouterProvider router={router} />;
}

export default App;
