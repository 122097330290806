import { NavLink } from 'react-router-dom';

type Options = {
    options: {
        title: string;
        options: {
            label: string;
            path: string;
        }[];
    }[];
};

const SideBarOptions: React.FC<Options> = ({ options }) => {
    const navStyles: string = 'p-2 bg-slate-200 rounded-md w-full block';
    return (
        <div className="p-2">
            {options.map((el, index) => (
                <div key={index}>
                    <p className="font-semibold">{el.title}</p>
                    <div className="flex flex-col gap-2">
                        {el.options.map((option, index) => (
                            <NavLink
                                key={index}
                                to={option.path}
                                className={({ isActive }) =>
                                    isActive
                                        ? `${navStyles} bg-slate-800 text-white`
                                        : navStyles
                                }
                            >
                                {option.label}
                            </NavLink>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SideBarOptions;
