import { useEffect, useState } from 'react';
import shopeeLiveApi, { LiveSessionType } from '../../api/shopeeLiveApi';
import Select from 'react-select';
import Modal from '../../components/Modal';
import SuccessPopup from '../../components/Modal/SuccessPopup';
import handleErrorAsync from '../../utils/handleErrorAsync';

export const ShopeeLive = () => {
    const [pendingLives, setPendingLive] = useState<LiveSessionType[]>([]);
    const [goLives, setGolives] = useState<LiveSessionType[]>([]);
    const [sessionId, setSessionId] = useState<number | undefined>();
    const [goliveSessionId, setGoliveSessionId] = useState<
        number | undefined
    >();
    const [errorMsg, setMessage] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    useEffect(() => {
        const fetchPendingLive = async () => {
            try {
                const { data } = await shopeeLiveApi.getSessionV2('status=1');
                setPendingLive(data.liveSessions);
            } catch (error) {
                console.log(error);
            }
        };
        const fetchGoLive = async () => {
            try {
                const { data } = await shopeeLiveApi.getSessionV2('status=2');
                setGolives(data.liveSessions);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPendingLive();
        fetchGoLive();
    }, []);

    const options = pendingLives.map((el) => ({
        value: el.id,
        label: el.title,
    }));

    const goliveOptions = goLives.map((el) => ({
        value: el.id,
        label: el.title,
    }));

    const onSelect = (value: { value: number; label: string } | null) => {
        if (value) {
            setSessionId(value.value);
        }
    };

    const onSelectGoLive = (value: { value: number; label: string } | null) => {
        if (value) {
            setGoliveSessionId(value.value);
        }
    };

    const onStartLive = async () => {
        if (!sessionId) return;
        try {
            await shopeeLiveApi.likeSessionProducts(sessionId);
            await shopeeLiveApi.goliveV2(sessionId);
            console.log('Start Live Success');
            setMessage('LÊN LIVE THÀNH CÔNG');
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setMessage);
        }
        setOpenModal(true);
    };

    const onOffLive = async () => {
        if (!goliveSessionId) return;
        try {
            await shopeeLiveApi.offLive();
            console.log('Off Live Success');
            setMessage('OFF LIVE THÀNH CÔNG');
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setMessage);
        }
        setOpenModal(true);
    };

    return (
        <div className="p-4 w-full flex flex-col justify-between h-dvh">
            <div className="flex flex-col gap-4 items-center justify-center w-full">
                <p>Các phiên chờ LIVE</p>
                <div className="w-full">
                    <Select
                        options={options}
                        onChange={onSelect}
                        menuPlacement="auto"
                    />
                </div>
                <button
                    onClick={onStartLive}
                    className="bg-green-600 text-white p-3"
                >
                    LÊN LIVE!
                </button>
            </div>
            <div className="flex flex-col gap-4 items-center justify-center w-full">
                <p>OFF LIVE</p>
                <div className="w-full">
                    <Select
                        options={goliveOptions}
                        onChange={onSelectGoLive}
                        menuPlacement="auto"
                    />
                </div>
                <button
                    onClick={onOffLive}
                    className="bg-red-600 text-white p-3"
                >
                    OFF LIVE!
                </button>
            </div>
            <Modal isOpen={openModal}>
                <SuccessPopup successMsg={errorMsg} setModal={setOpenModal} />
            </Modal>
        </div>
    );
};
