import React, { useEffect, useState } from 'react';
import authApi from '../../../api/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Pagination, Table } from 'antd';

function User() {
    const [users, setUsers] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [filter, setFilter] = useState({
        limit: 20,
        page: 1,
        userId: '',
        sort: '',
    });

    const dataSource = users.map((el, index) => ({
        key: index,
        id: el.userId,
        email: el.email,
        pendingCash: el.pendingCash || 0,
        withdraw: el.withdraw || 0,
        paid: el.paid || 0,
        bonus: el.bonus || 0,
        check: el.paid - el.withdraw + el.bonus,
        cash: el.cash,
    }));

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        {
            title: 'Số dư',
            dataIndex: 'cash',
            key: 'cash',
            render(_, { cash }) {
                return parseFloat(cash).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Chờ duyệt',
            dataIndex: 'pendingCash',
            key: 'pendingCash',
            render(_, { pendingCash }) {
                if (!pendingCash) return '-';
                return parseFloat(pendingCash).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Đã duyệt',
            dataIndex: 'paid',
            key: 'paid',
            sorter: (a, b) => a.paid - b.paid,
            render(_, { paid }) {
                if (!paid) return '-';
                return parseFloat(paid).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Thưởng',
            dataIndex: 'bonus',
            key: 'bonus',
            render(_, { bonus }) {
                if (!bonus) return '-';
                return parseFloat(bonus).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Đã rút',
            dataIndex: 'withdraw',
            key: 'withdraw',
            sorter: (a, b) => a.withdraw - b.withdraw,
            render(_, { withdraw }) {
                if (!withdraw) return '-';
                return parseFloat(withdraw).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Check',
            dataIndex: 'check',
            key: 'check',
            sorter: (a, b) => a.check - b.check,
            render(_, { check }) {
                if (check >= 0) {
                    return (
                        <FontAwesomeIcon
                            className="text-green-600"
                            icon={faCheckCircle}
                        />
                    );
                } else
                    return (
                        <FontAwesomeIcon
                            className="text-orange-600"
                            icon={faWarning}
                        />
                    );
            },
        },
    ];

    useEffect(() => {
        const fetchAllUser = async () => {
            const queryString = Object.keys(filter)
                .reduce((acc, cur) => {
                    if (filter[cur]) acc.push(`${cur}=${filter[cur]}`);
                    return acc;
                }, [])
                .join('&');
            const { data } = await authApi.getAllUser(queryString);
            setUsers(data.users);
            setTotalUser(data.totalUser);
        };
        fetchAllUser();
    }, [filter]);

    const onChangePageAndSize = (page, limit) => {
        setFilter((prev) => ({ ...prev, userId: '', page, limit }));
    };

    return (
        <div className="p-4 w-full h-dvh flex flex-col gap-2 overflow-auto">
            <p className="p-2 text-xl font-bold">Danh sách người dùng</p>
            <div>
                <input
                    value={filter.userId}
                    onChange={(e) =>
                        setFilter((prev) => ({
                            ...prev,
                            userId: e.target.value,
                        }))
                    }
                    type="text"
                    className="p-2 rounded-lg"
                    placeholder="Nhập ID"
                />
            </div>
            <div className="overflow-auto">
                <Table
                    size="small"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    onChange={(a, b, c, d) => console.log(c)}
                />
            </div>
            <div className="flex justify-end">
                <Pagination
                    total={totalUser}
                    defaultCurrent={filter.page}
                    defaultPageSize={filter.limit}
                    showSizeChanger
                    pageSizeOptions={[20, 50, 100, 200]}
                    onChange={onChangePageAndSize}
                />
            </div>
        </div>
    );
}

export default User;
