import SideBar from '../../components/SideBar/SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store';

function Home() {
    const navigate = useNavigate();
    const auth = useAppSelector((state) => state.auth);
    if (auth.isLogined && auth.userInfo!.role !== 'admin') {
        localStorage.removeItem('access_token');
        navigate('/login');
    }
    return (
        <div className="h-dvh flex">
            <SideBar />
            <Outlet />
        </div>
    );
}

export default Home;
