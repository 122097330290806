import React, { useState } from 'react';
import authApi from '../../../api/authApi';
import Modal from '../../../components/Modal';
import YesNo from '../../../components/Modal/YesNo';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import handleErrorAsync from '../../../utils/handleErrorAsync';

function Bonus() {
    const [inputs, setInputs] = useState({
        userId: '',
        bonusCash: '',
        title: '',
        note: '',
    });

    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const onCloseModal = () => {
        setOpenModal(false);
    };

    const onOpenModal = () => {
        if (Object.keys(inputs).some((el) => !inputs[el]))
            return console.log('Invalid Form');
        setOpenModal(true);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            await authApi.bonusCash({
                ...inputs,
                bonusCash: inputs.bonusCash.replace(/\./g, '') * 1,
            });
            toast('Thành công', {
                type: 'success',
            });
            setInputs({ bonusCash: '', note: '', title: '', userId: '' });
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setErrorMsg);
            toast('Thất bại', {
                type: 'error',
            });
        }
        setIsLoading(false);
        onCloseModal();
    };

    const onInput = (e) => {
        setErrorMsg('');
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'bonusCash') {
            value = value.replace(/[^0-9]/g, '');
            setInputs((prev) => ({
                ...prev,
                bonusCash: value
                    ? parseFloat(value).toLocaleString(undefined, {
                          useGrouping: true,
                      })
                    : '',
            }));
            return;
        }
        setInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    return (
        <div className="h-dvh w-full flex flex-col justify-center items-center">
            <ToastContainer />
            {errorMsg && (
                <div>
                    <p className="text-red-500">{errorMsg}</p>
                </div>
            )}
            <div className="w-[720px]">
                <h2>Thưởng Tiền User</h2>
                <div className="flex flex-col gap-3">
                    <div>
                        <label htmlFor="userId">User ID</label>
                        <input
                            id="userId"
                            value={inputs.userId}
                            onChange={onInput}
                            className="p-3 bg-white shadow-md border w-full rounded-md"
                            type="number"
                            name="userId"
                            placeholder="User ID"
                        />
                    </div>
                    <div>
                        <label htmlFor="bonusCash">Số tiền thưởng</label>
                        <input
                            id="bonusCash"
                            value={inputs.bonusCash}
                            onChange={onInput}
                            className="p-3 bg-white shadow-md border w-full rounded-md"
                            type="text"
                            name="bonusCash"
                            placeholder="Nhập số tiền thưởng"
                        />
                    </div>
                    <div>
                        <label htmlFor="title">Tiêu đề</label>
                        <input
                            id="title"
                            value={inputs.title}
                            onChange={onInput}
                            className="p-3 bg-white shadow-md border w-full rounded-md"
                            type="text"
                            name="title"
                            placeholder="Tiêu đề"
                        />
                    </div>
                    <textarea
                        id="note"
                        value={inputs.note}
                        onChange={onInput}
                        className="p-3 bg-white shadow-md border rounded-md"
                        placeholder="Nội dung"
                        name="note"
                        cols="30"
                        rows="4"
                    ></textarea>
                    <button
                        onClick={onOpenModal}
                        className="p-3 bg-pink-600 text-white mt-4"
                    >
                        Xác nhận
                    </button>
                </div>
            </div>
            <Modal isOpen={openModal}>
                <YesNo
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                    title={'Thao tác THƯỞNG TIỀN cho người dùng'}
                    onClose={onCloseModal}
                    content={
                        <div>
                            <div className="flex justify-between">
                                <span>UserId:</span>
                                <span>{inputs.userId}</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Số tiền:</span>
                                <span>{inputs.bonusCash}</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Tiêu đề:</span>
                                <span>{inputs.title}</span>
                            </div>
                            <div className="flex justify-between text-right">
                                <span className="whitespace-nowrap">
                                    Nội dung:
                                </span>
                                <span>{inputs.note}</span>
                            </div>
                        </div>
                    }
                />
            </Modal>
        </div>
    );
}

export default Bonus;
