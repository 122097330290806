import {
    faCloudUpload,
    faImage,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import formatMoney from '../../../utils/formatMoney';
import paymentApi from '../../../api/paymentApi';
import { format, fromUnixTime } from 'date-fns';
import vietQrApi from '../../../api/vietQrApi';

const UpdatePaymentRequest = ({
    setOpenModal,
    payment,
    setPayment,
    fetchPayment,
}) => {
    const fileRef = useRef();
    const [bill, setBill] = useState();
    const [note, setNote] = useState('');
    const [qrUrl, setQrUrl] = useState('');

    const paymentNote =
        process.env.REACT_APP_PAYMENT_NOTE || 'Thanh toán yêu cầu rút tiền ID';

    const handleOpenSelectFile = () => {
        fileRef.current?.click();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setBill(undefined);
        setNote('');
        setQrUrl('');
    };

    const handleAddBill = (e) => {
        if (e.currentTarget.files) {
            setBill(e.currentTarget.files[0]);
        }

        if (fileRef.current) {
            fileRef.current.value = '';
        }
    };

    const handleAddNote = (e) => {
        setNote(e.currentTarget.value);
    };

    let billUrl;
    if (bill) {
        billUrl = URL.createObjectURL(bill);
    }

    const handleCompletePayment = async () => {
        if (bill && note && payment) {
            try {
                const form = new FormData();
                form.append('image', bill);
                form.append('note', note);
                await paymentApi.completePayment(payment.paymentId, form);
                fetchPayment();

                //Close Modal
                handleCloseModal();
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onPaste = (e) => {
        console.log(e.clipboardData.files);
        const file = e.clipboardData.files[0];
        setBill(file);
    };

    useEffect(() => {
        const fetchQR = async () => {
            if (payment && payment.Bank?.bin) {
                try {
                    const { data } = await vietQrApi.generateQr(
                        payment.bankAccountNumber,
                        payment.bankAccountName,
                        payment.Bank.bin,
                        payment.amount,
                        payment.paymentId,
                        `${paymentNote} ${payment.paymentId}`
                    );
                    console.log(data);
                    setQrUrl(data.data.qrDataURL);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchQR();
        setNote(`Thanh toán thành công requestId ${payment.paymentId}`);
    }, [payment, paymentNote]);
    return (
        <div className="bg-white p-4 flex gap-4">
            {qrUrl && <img src={qrUrl} alt="qrcode" className="h-96" />}
            <div className="flex flex-col gap-4 min-w-[500px]">
                <h1>Cập nhật yêu cầu rút tiền</h1>
                {payment && (
                    <div className="flex flex-col gap-1">
                        <p className="font-semibold">Thông tin chi tiết:</p>
                        <div className="flex justify-between">
                            <span>Mã yêu cầu:</span>
                            <span>#{payment.paymentId}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Ngày tạo:</span>
                            <span>
                                {format(
                                    fromUnixTime(payment.createTime),
                                    'dd-MM-yyyy hh:mm:ss'
                                )}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span>User ID:</span>
                            <span>#{payment.userId}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Chủ tài khoản:</span>
                            <span>{payment.bankAccountName}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Số tài khoản:</span>
                            <span>{payment.bankAccountNumber}</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Ngân hàng:</span>
                            <span>{payment.Bank?.bankName.split('-')[0]}</span>
                        </div>
                        <div className="flex justify-between text-2xl font-semibold">
                            <span>Số tiền:</span>
                            <span className="text-pink-600">
                                {formatMoney.format(payment.amount)}
                            </span>
                        </div>
                    </div>
                )}
                <div className="flex flex-col gap-4 border-t py-2">
                    <p>Bill chuyển khoản</p>
                    <div
                        onClick={handleOpenSelectFile}
                        className="p-6 h-36 cursor-pointer border-dashed border-4 rounded-lg flex flex-col justify-center items-center text-slate-400 hover:border-pink-600"
                    >
                        {!bill && (
                            <>
                                <FontAwesomeIcon
                                    className="text-4xl"
                                    icon={faCloudUpload}
                                />
                                <span>Upload Bill chuyển khoản</span>
                            </>
                        )}
                        {bill && (
                            <FontAwesomeIcon
                                className="text-4xl text-slate-800"
                                icon={faImage}
                            />
                        )}
                    </div>
                    <input
                        onChange={handleAddBill}
                        type="file"
                        name="bill"
                        id="bill"
                        hidden
                        ref={fileRef}
                    />
                    <input
                        onPaste={onPaste}
                        type="text"
                        className="bg-slate-200 px-2 py-5 rounded-lg"
                        name="paste"
                        id="paste"
                        placeholder="paste bill"
                    />
                    <input
                        onChange={handleAddNote}
                        value={note}
                        className="bg-slate-200 p-2 rounded-lg"
                        type="text"
                        name="note"
                        id="node"
                        placeholder="Ghi chú"
                    />
                </div>
                <div className="flex justify-between gap-3">
                    <button
                        onClick={handleCompletePayment}
                        disabled={!bill && !note}
                        className="p-2 w-full rounded-lg border disabled:opacity-60 disabled:cursor-not-allowed"
                    >
                        Thanh toán
                    </button>
                    <button
                        onClick={handleCloseModal}
                        className="p-2 w-full rounded-lg bg-pink-600 text-white"
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
            </div>
            {bill && (
                <div className="w-[300px]">
                    <img
                        src={billUrl}
                        alt="bill"
                        className="object-contain h-full"
                    />
                </div>
            )}
        </div>
    );
};

export default UpdatePaymentRequest;
