import { useEffect, useState } from 'react';
import vietQrApi from '../../../api/vietQrApi';
import gameApi from '../../../api/gameApi';
import { Select } from 'antd';

function PaidCoin({ onClose, userInfo, reloadUsers }) {
    const [bill, setBill] = useState();
    const [qrUrl, setQrUrl] = useState('');
    const [note, setNote] = useState('');
    const [status, setStatus] = useState();
    const onPaste = (e) => {
        const file = e.clipboardData.files[0];
        setBill(file);
    };

    const statusOptions = [
        {
            label: 'Success',
            value: 2,
        },
        {
            label: 'Pending',
            value: 1,
        },
    ];

    const PAYMENT_NOTE = `chietkhaupro trao thuong userid ${
        userInfo?.userId || ''
    }`;

    let billUrl;
    if (bill) {
        billUrl = URL.createObjectURL(bill);
    }

    const onSumbit = async () => {
        if (!userInfo || !bill || !status) return;
        const formData = new FormData();
        formData.append('userId', userInfo.userId);
        formData.append('image', bill);
        formData.append('status', status);
        formData.append('note', note);
        try {
            await gameApi.resetUserCoin(formData);
            await reloadUsers();
            onCancel();
        } catch (error) {
            console.log(error);
        }
    };

    const onCancel = () => {
        setQrUrl();
        onClose();
        setBill();
        setNote('');
    };

    useEffect(() => {
        if (!userInfo || !userInfo?.bankInfo) return;
        const fetchQR = async () => {
            if (userInfo.bankInfo?.bin) {
                try {
                    const { data } = await vietQrApi.generateQr(
                        userInfo.accountNumber,
                        userInfo.accountName,
                        userInfo.bankInfo.bin,
                        userInfo.coin,
                        userInfo.username,
                        `${PAYMENT_NOTE}`
                    );
                    setQrUrl(data.data.qrDataURL);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchQR();
    }, [userInfo, PAYMENT_NOTE]);
    return (
        <div className="w-full flex justify-center items-center h-[580px]">
            {qrUrl && (
                <div className="h-full bg-white">
                    <div className="h-2/3">
                        <img
                            src={qrUrl}
                            alt="bill"
                            className="h-full object-cover"
                        />
                    </div>
                </div>
            )}
            <div className="w-[480px] bg-white p-4 h-full flex flex-col justify-between">
                <div className="flex flex-col gap-3">
                    <h4>Thông tin chi tiết:</h4>
                    {userInfo && (
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-between">
                                <span>userId:</span>
                                <span>{userInfo.userId}</span>
                            </div>
                            <div className="flex justify-between">
                                <span>usename:</span>
                                <span>{userInfo.username}</span>
                            </div>
                            {userInfo.bankInfo && (
                                <>
                                    <div className="flex justify-between">
                                        <span>Ngân hàng:</span>
                                        <span>
                                            {
                                                userInfo.bankInfo.bankName.split(
                                                    '-'
                                                )[0]
                                            }
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span>Chủ tài khoản:</span>
                                        <span>{userInfo.accountName}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span>Số tài khoản:</span>
                                        <span>{userInfo.accountNumber}</span>
                                    </div>
                                </>
                            )}
                            {!userInfo.bankInfo && (
                                <div className="flex justify-between">
                                    <span>Hình thức thanh toán:</span>
                                    <span className="text-orange-500">
                                        Shopee Pay
                                    </span>
                                </div>
                            )}
                            <div className="flex justify-between">
                                <span>số tiền:</span>
                                <span>
                                    {parseFloat(userInfo.coin).toLocaleString(
                                        'vi-VN'
                                    )}
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>Nội dung:</span>
                                <span className="text-end">{PAYMENT_NOTE}</span>
                            </div>
                        </div>
                    )}
                    <div>
                        <input
                            className="p-3 bg-slate-200 w-full rounded-md"
                            type="text"
                            placeholder="paste Bill"
                            onPaste={onPaste}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Select
                            placeholder="Trạng thái"
                            options={statusOptions}
                            allowClear
                            onChange={(value) => setStatus(value)}
                            value={status}
                        />
                    </div>
                    <div>
                        <input
                            className="p-5 bg-slate-200 w-full rounded-md focus-within:border focus-within:border-pink-600"
                            type="text"
                            placeholder="Nhập ghi chú"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex justify-between gap-3">
                    <button
                        onClick={onSumbit}
                        className="w-full p-3 border rounded-md"
                    >
                        Xác nhận
                    </button>
                    <button
                        onClick={onCancel}
                        className="w-full p-3 bg-pink-600 text-white rounded-md"
                    >
                        Huỷ
                    </button>
                </div>
            </div>
            {bill && (
                <div className="h-full">
                    <img
                        src={billUrl}
                        alt="bill"
                        className="h-full object-cover"
                    />
                </div>
            )}
        </div>
    );
}

export default PaidCoin;
