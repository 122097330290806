import { Link } from 'react-router-dom';
import SideBarOptions from './SideBarOptions';

function SideBar() {
    const options = [
        {
            title: 'Thanh toán',
            options: [
                {
                    path: '/dashboard',
                    label: 'Tổng quan',
                },
                {
                    path: '/payment',
                    label: 'Thanh toán',
                },
                {
                    path: '/user',
                    label: 'User',
                },
                {
                    path: '/topuser',
                    label: 'Top User',
                },
                {
                    path: '/order',
                    label: 'Đơn Hàng',
                },
                {
                    path: '/commission',
                    label: 'Set Hoa Hồng',
                },
                {
                    path: '/bonus',
                    label: 'Thưởng Tiền User',
                },
                {
                    path: '/gamecoin',
                    label: 'MiniGame',
                },
                {
                    path: '/pendingcoin',
                    label: 'PendingCoin',
                },
            ],
        },
    ];

    const handleLogout = () => {
        localStorage.removeItem('access_token');
    };
    return (
        <div className="min-w-[208px] w-52 bg-white shadow-md h-full flex flex-col justify-between">
            <div>
                <p className="p-2 text-xl font-semibold">Admin</p>
                <SideBarOptions options={options} />
            </div>
            <div className="p-2 w-full">
                <Link
                    to={'/login'}
                    onClick={handleLogout}
                    className="p-3 flex bg-pink-600 text-white rounded-md"
                >
                    Logout
                </Link>
            </div>
        </div>
    );
}

export default SideBar;
