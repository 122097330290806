import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import paymentApi from '../../../api/paymentApi';
import { format, fromUnixTime } from 'date-fns';
import formatMoney from '../../../utils/formatMoney';

function PaymentDetail() {
    const params = useParams();
    const [payment, setPayment] = useState();
    useEffect(() => {
        const fetchPayment = async () => {
            const { data } = await paymentApi.getAll(`paymentId=${params.id}`);
            setPayment(data.data.payments[0]);
        };
        fetchPayment();
    }, [params]);
    return (
        <div className="w-full p-4">
            <p className="p-4 text-xl font-semibold">Thông tin giao dịch</p>
            {payment && (
                <div className="w-full">
                    <div className="flex justify-between">
                        <span>Ngày tạo</span>
                        <span>
                            {format(
                                fromUnixTime(payment.createTime),
                                'dd-MM-yyyy hh:mm:ss'
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <span>Ngày tạo</span>
                        {payment.completeTime && (
                            <span>
                                {format(
                                    fromUnixTime(payment?.completeTime),
                                    'dd-MM-yyyy hh:mm:ss'
                                )}
                            </span>
                        )}
                    </div>
                    <div className="flex justify-between">
                        <span>Người tạo</span>
                        <div className="flex">
                            <span>{payment.user.name}</span>
                            <span>{payment.user.userId}</span>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span>Số tiền</span>
                        <span>{formatMoney.format(payment.amount)}</span>
                    </div>
                    <div className="flex justify-between">
                        <span>Ngân hàng</span>
                        <span>{payment.Bank?.bankName}</span>
                    </div>
                    <div className="flex justify-between">
                        <span>Số tài khoản</span>
                        <span>{payment.bankAccountNumber}</span>
                    </div>
                    <div className="flex justify-between">
                        <span>Bill chuyển khoản</span>
                        <img
                            className="w-80"
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/img/payment-bill/${payment.paymentImage}`}
                            alt="bill"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default PaymentDetail;
