import axiosClient from './axiosClient';

const paymentApi = {
    getAll(query?: string) {
        const baseURL = `/payment?${query || ''}`;
        return axiosClient.get(baseURL);
    },
    completePayment(id: number, data: FormData) {
        const baseURL = `/payment/complete/${id}`;
        return axiosClient.post(baseURL, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    cancellPayment(id: number, data: { note: string }) {
        const baseURL = `/payment/cancell/${id}`;
        return axiosClient.put(baseURL, data);
    },
};

export default paymentApi;
