import axios from 'axios';

const vietQrApi = {
    generateQr(
        accountNo: number | string,
        accountName: string,
        acqId: number,
        amount: number,
        paymendId: number,
        addInfo: string
    ) {
        const baseURL = 'https://api.vietqr.io/v2/generate';
        return axios.post<{
            code: string;
            desc: string;
            data: {
                acpId: number;
                accountName: string;
                qrCode: string;
                qrDataURL: string;
            };
        }>(baseURL, {
            accountNo,
            accountName,
            acqId,
            amount,
            addInfo,
            format: 'text',
            template: 'compact2',
        });
    },
};

export default vietQrApi;
