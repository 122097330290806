import React, { useEffect, useState } from 'react';
import authApi from '../../../api/authApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Commission() {
    const [campaigns, setCampaign] = useState([]);
    const [isEditting, setIsEditting] = useState(false);

    const onOpenEdit = () => {
        setIsEditting(true);
    };

    const getCampaigns = async () => {
        try {
            const { data } = await authApi.getCampaign();
            const campaignsModified = data.campaigns.map((el) => ({
                ...el,
                userRate: el.userRate * 100,
            }));
            setCampaign(campaignsModified);
        } catch (error) {
            console.log(error);
        }
    };

    const onUpdateCampaign = async () => {
        const campaignsToUpdate = campaigns.map((el) => ({
            ...el,
            userRate: el.userRate / 100,
        }));
        console.log(campaignsToUpdate);
        try {
            await authApi.updateCampaign({ campaignList: campaignsToUpdate });
        } catch (error) {}
        onCloseEdit();
        toast('Cập nhật thành công', {
            type: 'success',
        });
    };

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const { data } = await authApi.getCampaign();
                const campaignsModified = data.campaigns.map((el) => ({
                    ...el,
                    userRate: el.userRate * 100,
                }));
                setCampaign(campaignsModified);
            } catch (error) {
                console.log(error);
            }
        };
        fetchCampaign();
    }, []);

    const onCloseEdit = async () => {
        setIsEditting(false);
        try {
            getCampaigns();
        } catch (error) {
            console.log(error);
        }
    };

    const onEdit = (e, campId) => {
        setCampaign((prev) =>
            prev.map((el) => {
                if (el.campId !== campId) return el;
                return { ...el, userRate: e.target.value };
            })
        );
    };
    return (
        <div className="flex justify-center items-center flex-col w-full h-dvh">
            <div className="w-full p-4 md:w-[600px]">
                <table className="bg-white rounded-lg w-full border shadow-md">
                    <thead className="text-left">
                        <tr>
                            <th className="p-3">Kênh</th>
                            <th className="p-3">% User</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaigns.map((el, index) => (
                            <tr
                                key={index}
                                className="border-b last:border-none"
                            >
                                <td className="p-3">{el.campId}</td>
                                <td className="p-3">
                                    <div className="relative">
                                        {isEditting ? (
                                            <input
                                                className="bg-slate-200 p-2"
                                                onChange={(e) =>
                                                    onEdit(e, el.campId)
                                                }
                                                type="number"
                                                value={el.userRate}
                                            />
                                        ) : (
                                            `${el.userRate} %`
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w-full p-4 md:w-[600px]">
                {!isEditting && (
                    <button
                        onClick={onOpenEdit}
                        className="bg-pink-600 text-white p-2 rounded-md"
                    >
                        Chỉnh sửa
                    </button>
                )}
                {isEditting && (
                    <div className="flex gap-3">
                        <button
                            onClick={onUpdateCampaign}
                            className=" border border-pink-600 p-2 rounded-md"
                        >
                            Cập nhật
                        </button>
                        <button
                            onClick={onCloseEdit}
                            className="bg-pink-600 text-white p-2 rounded-md"
                        >
                            Huỷ
                        </button>
                    </div>
                )}
            </div>
            <ToastContainer />
        </div>
    );
}

export default Commission;
