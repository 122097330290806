import React, { useEffect, useState } from 'react';
import paymentApi from '../../../api/paymentApi';
import { Pagination, Table } from 'antd';
import convertStatus from '../../../utils/convertStatus';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import formatMoney from '../../../utils/formatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import UpdatePaymentRequest from './UpdatePaymentRequest';
import CancellPayment from './CancellPayment';
import Modal from '../../../components/Modal';
import Filter from './Filter';

function Payment() {
    const [payments, setPayments] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [filter, setFilter] = useState({
        limit: 10,
        page: 1,
        status: null,
        startDate: undefined,
        endDate: undefined,
    });
    const fetchPayments = async () => {
        const cloneFilter = { ...filter };
        const queryString = Object.keys(cloneFilter)
            .reduce((acc, cur) => {
                if (!cloneFilter[cur]) return acc;
                if (cur === 'startDate') {
                    acc.push(`createTime[gte]=${cloneFilter[cur]}`);
                    return acc;
                }
                if (cur === 'endDate') {
                    acc.push(`createTime[lte]=${cloneFilter[cur]}`);
                    return acc;
                }
                acc.push(`${cur}=${cloneFilter[cur]}`);
                return acc;
            }, [])
            .join('&');
        const { data } = await paymentApi.getAll(queryString);
        setPayments(data.data.payments);
        setTotalPayment(data.data.totalPayment);
    };
    useEffect(() => {
        const fetchPayments = async () => {
            const cloneFilter = { ...filter };
            const queryString = Object.keys(cloneFilter)
                .reduce((acc, cur) => {
                    if (!cloneFilter[cur]) return acc;
                    if (cur === 'startDate') {
                        acc.push(`createTime[gte]=${cloneFilter[cur]}`);
                        return acc;
                    }
                    if (cur === 'endDate') {
                        acc.push(`createTime[lte]=${cloneFilter[cur]}`);
                        return acc;
                    }
                    acc.push(`${cur}=${cloneFilter[cur]}`);
                    return acc;
                }, [])
                .join('&');
            const { data } = await paymentApi.getAll(queryString);
            setPayments(data.data.payments);
            setTotalPayment(data.data.totalPayment);
        };
        fetchPayments();
    }, [filter]);

    const dataSource = payments.map((el, index) => ({
        key: el.paymentId,
        paymentId: el.paymentId,
        status: el.status,
        createTime: el.createTime,
        completeTime: el.completeTime,
        user: el.user,
        admin: el.adminProcessId,
        amount: el.amount,
        bankInfo: {
            bankAccountNumber: el.bankAccountNumber,
            bankAccountName: el.bankAccountName,
        },
        paymentInfo: el,
        note: el.note,
        check: el,
    }));

    const columns = [
        {
            title: 'Reqest ID',
            dataIndex: 'paymentId',
            key: 'paymentId',
            render(_, { paymentId }) {
                return '#' + paymentId;
            },
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render(_, { user }) {
                return (
                    <div>
                        <div className="flex gap-2">
                            <span>Tên:</span>
                            <span>{user.name || 'userId-' + user.userId}</span>
                        </div>
                        <div className="flex gap-2">
                            <span>userId:</span>
                            <span>{user.userId}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            render(_, { amount }) {
                return formatMoney.format(amount);
            },
        },
        {
            title: 'Chủ tài khoản',
            dataIndex: 'bankInfo',
            key: 'bankInfo',
            render(_, { bankInfo }) {
                return (
                    <div className="flex flex-col">
                        <span>{bankInfo.bankAccountName}</span>
                        <span>{bankInfo.bankAccountNumber}</span>
                    </div>
                );
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render(_, { status, paymentId }) {
                return (
                    <div>
                        <span
                            className={`px-1 ${
                                convertStatus(status).style
                            } rounded-md`}
                        >
                            {convertStatus(status).label}
                        </span>
                        {status === 2 && (
                            <Link
                                to={`${paymentId}`}
                                className="ml-2 underline"
                            >
                                detail
                            </Link>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createTime',
            key: 'createTime',
            render(_, { createTime }) {
                return format(
                    new Date(fromUnixTime(createTime)),
                    'dd/MM/yyyy HH:mm:ss'
                );
            },
        },
        {
            title: 'Ngày thanh toán',
            dataIndex: 'completeTime',
            key: 'completeTime',
            render(_, { completeTime }) {
                if (!completeTime) return '-';
                return format(
                    new Date(fromUnixTime(completeTime)),
                    'dd/MM/yyyy HH:mm:ss'
                );
            },
        },

        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'admin',
            dataIndex: 'admin',
            key: 'admin',
        },
        {
            title: 'Check',
            render(_, { paymentInfo }) {
                return (
                    <div className="flex items-center">
                        {paymentInfo.status !== 1 && (
                            <FontAwesomeIcon
                                className="text-gray-400"
                                icon={faCheckCircle}
                            />
                        )}
                        {paymentInfo.status === 1 && (
                            <div>
                                {paymentInfo.totalAmountWithdraw +
                                    paymentInfo.amount >
                                paymentInfo.totalCommission +
                                    paymentInfo.bonus ? (
                                    <div className="flex flex-col items-start">
                                        <FontAwesomeIcon
                                            className="text-red-600"
                                            icon={faWarning}
                                        />
                                        <span>{`${formatMoney.format(
                                            paymentInfo.totalAmountWithdraw
                                        )} / ${formatMoney.format(
                                            paymentInfo.totalCommission +
                                                paymentInfo.bonus
                                        )}`}</span>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-start">
                                        <FontAwesomeIcon
                                            className="text-green-600"
                                            icon={faCheckCircle}
                                        />
                                        <span>{`${formatMoney.format(
                                            paymentInfo.totalAmountWithdraw
                                        )} / ${formatMoney.format(
                                            paymentInfo.totalCommission +
                                                paymentInfo.bonus
                                        )}`}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Thao tác',
            render(_, { paymentInfo }) {
                return (
                    <div className="flex gap-2">
                        <button
                            disabled={paymentInfo.status !== 1}
                            onClick={() =>
                                handleOpenUpdatePayment(paymentInfo, 'complete')
                            }
                            className={`p-1 bg-pink-600 text-white rounded-lg disabled:opacity-60 disabled:cursor-not-allowed`}
                        >
                            Thanh toán
                        </button>
                        <button
                            disabled={paymentInfo.status !== 1}
                            onClick={() =>
                                handleOpenUpdatePayment(paymentInfo, 'cancell')
                            }
                            className="p-1 bg-slate-800 text-white rounded-lg disabled:opacity-60 disabled:cursor-not-allowed"
                        >
                            Huỷ bỏ
                        </button>
                    </div>
                );
            },
        },
    ];

    const [openModal, setOpenModal] = useState(false);
    const [paymentUpdate, setPaymentUpdate] = useState();
    const handleOpenUpdatePayment = (payment, type) => {
        setOpenModal(true);
        setPaymentUpdate({
            type,
            payment,
        });
    };
    const onChangePage = (page, limit) => {
        setFilter((prev) => ({ ...prev, page, limit }));
    };

    return (
        <div className="p-4 w-full flex flex-col gap-2">
            <div>
                <Filter filter={filter} setFilter={setFilter} />
            </div>
            <div className="w-full overflow-auto">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
            </div>
            <div className="flex justify-end">
                <Pagination
                    total={totalPayment}
                    pageSize={filter.limit}
                    pageSizeOptions={[10, 20, 50, 100, 500]}
                    showSizeChanger
                    onChange={onChangePage}
                    showTotal={(total) => `Tổng: ${total}`}
                />
            </div>
            <Modal isOpen={openModal}>
                {paymentUpdate?.type === 'complete' && (
                    <UpdatePaymentRequest
                        setOpenModal={setOpenModal}
                        payment={paymentUpdate?.payment}
                        setPayment={setPayments}
                        fetchPayment={fetchPayments}
                    />
                )}

                {paymentUpdate?.type === 'cancell' && (
                    <CancellPayment
                        setPayment={setPayments}
                        setOpenModal={setOpenModal}
                        payment={paymentUpdate?.payment}
                        fetchPayment={fetchPayments}
                    />
                )}
            </Modal>
        </div>
    );
}

export default Payment;
