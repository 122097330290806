import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import gameApi from '../../api/gameApi';
import { convertGameStatus } from './Game';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function GameDetail() {
    const { gameId } = useParams();
    const [game, setGame] = useState();
    const [questionText, setQuestionText] = useState([]);
    const fetchGameDetails = async () => {
        try {
            const { data } = await gameApi.getGameDetail(gameId);
            setGame(data.game);
            setQuestionText(data.questionText);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const fetchGameDetails = async () => {
            try {
                const { data } = await gameApi.getGameDetail(gameId);
                setGame(data.game);
                setQuestionText(data.questionText);
            } catch (error) {
                console.log(error);
            }
        };
        fetchGameDetails();
    }, [gameId]);

    const onStartGame = async () => {
        try {
            await gameApi.updateStatusGame(gameId, 2);
            fetchGameDetails();
        } catch (error) {
            console.log(error);
        }
    };

    const onEndGame = async () => {
        try {
            await gameApi.gameResult(gameId);
            fetchGameDetails();
        } catch (error) {
            console.log(error);
        }
    };

    const onStartQuestion = async (questionId) => {
        try {
            await gameApi.startQuestion(questionId);
            fetchGameDetails();
        } catch (error) {
            console.log(error);
        }
    };

    const onEndQuestion = async (questionId) => {
        try {
            await gameApi.endQuestion(questionId);
            fetchGameDetails();
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="p-4">
            <Link to={'/game_manager'}>
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="p-4 hover:bg-slate-200"
                />
            </Link>
            {game && (
                <div>
                    <div>
                        <h3>{game.gameName}</h3>
                        <span>{convertGameStatus(game.status).statusName}</span>
                        <div className="flex justify-between gap-2">
                            {game.status === 1 && (
                                <button
                                    onClick={onStartGame}
                                    className="p-3 w-full text-white text-center bg-green-600"
                                >
                                    START
                                </button>
                            )}
                            {game.status === 2 && (
                                <div className="flex justify-between gap-2 w-full">
                                    <button
                                        onClick={onEndGame}
                                        className="p-3 w-full text-white text-center bg-red-600"
                                    >
                                        END GAME
                                    </button>
                                </div>
                            )}
                            {game.status === 3 && (
                                <div className="p-3 flex justify-center items-center w-full bg-orange-500 text-white font-bold">
                                    <span>GAME ĐÃ KẾT THÚC!</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        {game.questions.map((el) => (
                            <div
                                className={`${
                                    el.status === 2
                                        ? 'bg-red-200'
                                        : el.status === 3
                                        ? 'bg-gray-300'
                                        : 'bg-white'
                                } p-2 rounded-md border shadow-md`}
                            >
                                <p>{`C${el.questionIndex}: ${el.question}`}</p>
                                <div className="flex gap-2">
                                    <span>Tiền thưởng:</span>
                                    <span className="font-bold text-amber-500">
                                        {el.questionReward}
                                    </span>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    {el.answers.map((answer) => (
                                        <div
                                            key={answer.answerId}
                                            className={`p-1 ${
                                                answer.isCorrectAnswer
                                                    ? 'bg-green-500'
                                                    : 'bg-red-50'
                                            }`}
                                        >
                                            <span>{`${answer.answerKey}. `}</span>
                                            <span>{answer.answer}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="p-1 mt-2">
                                    <p className="bg-gray-100">
                                        {questionText.find((text) =>
                                            text.includes(
                                                `C${el.questionIndex}.`
                                            )
                                        )}
                                    </p>
                                </div>
                                <div>
                                    {el.status === 1 && (
                                        <div className="p-2 w-full flex justify-end">
                                            <button
                                                className="p-1 bg-green-500 w-36"
                                                onClick={() =>
                                                    onStartQuestion(
                                                        el.questionId
                                                    )
                                                }
                                            >
                                                START
                                            </button>
                                        </div>
                                    )}
                                    {el.status === 2 && (
                                        <div className="p-2 w-full flex justify-end">
                                            <button
                                                onClick={() =>
                                                    onEndQuestion(el.questionId)
                                                }
                                                className="p-1 bg-red-500 w-36"
                                            >
                                                END
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default GameDetail;
