import React, { useEffect, useState } from 'react';
import authApi from '../../../api/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Select, Table } from 'antd';

function TopUser() {
    const [users, setUsers] = useState([]);
    const [sortBy, setSortBy] = useState(1);
    const [limit, setLimit] = useState(10);

    const dataSource = users.map((el, index) => ({
        key: index,
        id: el.userId,
        pendingCash: el.pendingCash || 0,
        withdraw: el.withdraw || 0,
        paid: el.paid || 0,
        bonus: el.bonus || 0,
        check: el.paid - el.withdraw + el.bonus,
    }));

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Chờ duyệt',
            dataIndex: 'pendingCash',
            key: 'pendingCash',
            sorter: (a, b) => a.pendingCash - b.pendingCash,
            render(_, { pendingCash }) {
                if (!pendingCash) return '-';
                return parseFloat(pendingCash).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Đã duyệt',
            dataIndex: 'paid',
            key: 'paid',
            sorter: (a, b) => a.paid - b.paid,
            render(_, { paid }) {
                if (!paid) return '-';
                return parseFloat(paid).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Thưởng',
            dataIndex: 'bonus',
            key: 'bonus',
            render(_, { bonus }) {
                if (!bonus) return '-';
                return parseFloat(bonus).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Đã rút',
            dataIndex: 'withdraw',
            key: 'withdraw',
            sorter: (a, b) => a.withdraw - b.withdraw,
            render(_, { withdraw }) {
                if (!withdraw) return '-';
                return parseFloat(withdraw).toLocaleString(undefined, {
                    useGrouping: true,
                });
            },
        },
        {
            title: 'Check',
            dataIndex: 'check',
            key: 'check',
            sorter: (a, b) => a.check - b.check,
            render(_, { check }) {
                if (check >= 0) {
                    return (
                        <FontAwesomeIcon
                            className="text-green-600"
                            icon={faCheckCircle}
                        />
                    );
                } else
                    return (
                        <FontAwesomeIcon
                            className="text-orange-600"
                            icon={faWarning}
                        />
                    );
            },
        },
    ];

    const onSelectTop = (value) => {
        const field = value.columnKey;
        switch (field) {
            case 'paid':
                setSortBy(1);
                break;
            case 'pendingCash':
                setSortBy(2);
                break;
            case 'withdraw':
                setSortBy(3);
                break;
            default:
                break;
        }
    };

    const onSelectLimit = (value) => {
        setLimit(value);
    };
    useEffect(() => {
        const fetchUsers = async () => {
            let api;
            switch (sortBy) {
                case 1:
                    api = authApi.topCommission;
                    break;
                case 2:
                    api = authApi.topPendingCash;
                    break;
                case 3:
                    api = authApi.topWithDraw;
                    break;
                default:
                    break;
            }
            try {
                const { data } = await api(`limit=${limit}`);
                setUsers(data.userList);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUsers();
    }, [sortBy, limit]);

    return (
        <div className="p-4 w-full flex flex-col gap-2 overflow-auto">
            <p>Top User</p>
            <Select
                options={[
                    {
                        label: 'Top 10',
                        value: 10,
                    },
                    {
                        label: 'Top 50',
                        value: 50,
                    },
                    {
                        label: 'Top 100',
                        value: 100,
                    },
                    {
                        label: 'Top 200',
                        value: 200,
                    },
                    {
                        label: 'Top 500',
                        value: 500,
                    },
                ]}
                defaultValue={{ label: 'Top 10', value: 10 }}
                className="w-40"
                onChange={onSelectLimit}
            />
            <div className="overflow-auto w-full">
                <Table
                    size="small"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    onChange={(a, b, c, d) => onSelectTop(c)}
                />
            </div>
        </div>
    );
}

export default TopUser;
