import React, { FC } from 'react';

interface ModalProps {
    children?: React.ReactNode;
    isOpen: boolean;
}

const Modal: FC<ModalProps> = ({ children, isOpen }) => {
    return (
        <div
            className={`fixed top-0 z-50 left-0 w-full h-full bg-gray-500 bg-opacity-60 flex justify-center items-center ${
                !isOpen ? 'hidden' : ''
            }`}
        >
            {children}
        </div>
    );
};

export default Modal;
