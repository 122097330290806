import React, { useEffect, useState } from 'react';
import gameApi from '../../api/gameApi';
import { Link } from 'react-router-dom';
const convertGameStatus = (status) => {
    const statusObj = {
        statusName: 'Sắp diễn ra',
        background: '',
        text: '',
    };
    switch (status) {
        case 1:
            statusObj.statusName = 'Sắp diễn ra';
            break;
        case 2:
            statusObj.statusName = 'Đang diễn ra';
            break;
        case 3:
            statusObj.statusName = 'Kết thúc';
            break;
        default:
            break;
    }

    return statusObj;
};
function Game() {
    const [games, setGames] = useState([]);
    const fetchGame = async () => {
        try {
            const { data } = await gameApi.getGameDashBoard();
            setGames(data.game.filter((el) => el.status !== 3));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchGame();
    }, []);
    return (
        <div className="p-4">
            <div className="flex flex-col gap-3">
                {games.map((el) => (
                    <div
                        key={el.gameId}
                        className="p-2 bg-white flex justify-between items-center h-32"
                    >
                        <div>
                            <h3 className="text-xl font-bold">{el.gameName}</h3>
                            <div className="flex gap-1">
                                <span>gameId:</span>
                                <span>{el.gameId}</span>
                            </div>
                            <div className="flex gap-1">
                                <span>Trạng thái:</span>
                                <span>
                                    {convertGameStatus(el.status).statusName}
                                </span>
                            </div>
                            <div className="flex gap-1 text-xl font-semibold text-pink-600">
                                <span>Tiền thưởng:</span>
                                <span>
                                    {parseFloat(el.reward).toLocaleString(
                                        'vi-VN'
                                    )}
                                </span>
                            </div>
                        </div>
                        <Link
                            to={'' + el.gameId}
                            className="bg-pink-600 text-white p-2"
                        >
                            Truy cập
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export { convertGameStatus };
export default Game;
