import React, { useEffect, useState } from 'react';
import gameApi from '../../../api/gameApi';
import formatMoney from '../../../utils/formatMoney';
import { Pagination, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Modal from '../../../components/Modal';
import PaidCoin from './PaidCoin';
const baseUrlImage = 'https://down-ws-vn.img.susercontent.com/';

function GameCoin() {
    const [users, setUsers] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [filter, setFilter] = useState({
        limit: 5,
        page: 1,
    });
    const [userInfo, setUserInfo] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const onChangePage = (page, limit) => {
        setFilter((prev) => ({ ...prev, page, limit }));
    };

    const dataSource = users.map((el, index) => ({
        key: el.userId,
        userId: el.userId,
        avatar: el.avatar,
        coin: el.coin,
        username: el.username,
        note: el.note,
        accountNumber: el.accountNumber,
        accountName: el.accountName,
        bankInfo: el.bankInfo,
    }));
    const columns = [
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: 'username',
            dataIndex: 'username',
            key: 'username',
            render(_, { username, avatar }) {
                return (
                    <div className="flex gap-1 items-center">
                        <div className="w-8 h-8 rounded-full overflow-hidden">
                            {avatar && (
                                <img
                                    className="w-full h-full object-cover"
                                    src={baseUrlImage + avatar}
                                    alt="avatar"
                                />
                            )}
                            {!avatar && (
                                <div className="bg-gray-300 w-full h-full flex justify-center items-center">
                                    <FontAwesomeIcon
                                        className="p-1"
                                        icon={faUser}
                                    />
                                </div>
                            )}
                        </div>
                        <span>{username || '---'}</span>
                    </div>
                );
            },
        },
        {
            title: 'Số tiền',
            dataIndex: 'coin',
            key: 'coin',
            render(_, { coin }) {
                return formatMoney.format(coin);
            },
        },
        {
            title: 'Thao tác',
            render(_, data) {
                return (
                    <div>
                        <button
                            onClick={() => {
                                setIsOpen(true);
                                setUserInfo(data);
                            }}
                            className="bg-pink-600 text-white p-1 rounded-md"
                        >
                            Thanh toán
                        </button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        const fetchUser = async () => {
            const cloneFilter = { ...filter };
            const queryString = Object.keys(cloneFilter)
                .reduce((acc, cur) => {
                    if (!cloneFilter[cur]) return acc;
                    acc.push(`${cur}=${cloneFilter[cur]}`);
                    return acc;
                }, [])
                .join('&');
            try {
                const { data } = await gameApi.getAllUser(queryString);
                setUsers(data.minigameUsers);
                setTotalUser(data.totalUser);
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
    }, [filter]);

    const fetchUser = async () => {
        const cloneFilter = { ...filter };
        const queryString = Object.keys(cloneFilter)
            .reduce((acc, cur) => {
                if (!cloneFilter[cur]) return acc;
                acc.push(`${cur}=${cloneFilter[cur]}`);
                return acc;
            }, [])
            .join('&');
        try {
            const { data } = await gameApi.getAllUser(queryString);
            setUsers(data.minigameUsers);
            setTotalUser(data.totalUser);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="w-full p-4">
            <div className="w-full overflow-auto">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
            </div>
            <div className="flex justify-end">
                <Pagination
                    total={totalUser}
                    pageSize={filter.limit}
                    pageSizeOptions={[10, 20, 50, 100, 500]}
                    showSizeChanger
                    onChange={onChangePage}
                    showTotal={(total) => `Tổng: ${total}`}
                />
            </div>
            <Modal isOpen={isOpen}>
                <PaidCoin
                    userInfo={userInfo}
                    onClose={() => setIsOpen(false)}
                    reloadUsers={fetchUser}
                />
            </Modal>
        </div>
    );
}

export default GameCoin;
