import React, { useEffect, useRef, useState } from 'react';
import authApi from '../../../api/authApi';
import formatMoney from '../../../utils/formatMoney';
import { DatePicker } from 'antd';
import { getUnixTime } from 'date-fns';

function DashBoard() {
    const [orderAnalytic, setOrderAnalytic] = useState({
        totalCommission: 0,
        totalUserCommission: 0,
    });
    const [merchantCom, setMerchantCom] = useState({
        lazada: 0,
        tiktok: 0,
        shopee: 0,
    });

    const [filter, setFilter] = useState({
        startDate: undefined,
        endDate: undefined,
    });

    const [paymentAnalytic, setPaymentAnalytic] = useState(0);
    const merchants = useRef(['lazada', 'tiktok', 'shopee']);

    const onSelectDate = (value) => {
        if (!value)
            return setFilter({
                endDate: undefined,
                startDate: undefined,
            });
        setFilter({
            startDate: getUnixTime(value[0]['$d']),
            endDate: getUnixTime(value[1]['$d']),
        });
    };
    useEffect(() => {
        const queryString = Object.keys(filter)
            .reduce((acc, cur) => {
                if (!filter[cur]) return acc;
                if (cur === 'startDate')
                    acc.push(`clickTime[gte]=${filter[cur]}`);
                if (cur === 'endDate')
                    acc.push(`clickTime[lte]=${filter[cur]}`);
                return acc;
            }, [])
            .join('&');
        const fetchOrderAnalytic = async () => {
            try {
                const { data } = await authApi.orderAnalytic(queryString || '');
                setOrderAnalytic({
                    totalCommission: data.totalCommission,
                    totalUserCommission: data.totalUserCommission,
                });
            } catch (error) {
                console.log(error);
            }
        };
        const fetchPaymentAnalytic = async () => {
            const queryString = Object.keys(filter)
                .reduce((acc, cur) => {
                    if (!filter[cur]) return acc;
                    if (cur === 'startDate')
                        acc.push(`completeTime[gte]=${filter[cur]}`);
                    if (cur === 'endDate')
                        acc.push(`completeTime[lte]=${filter[cur]}`);
                    return acc;
                }, [])
                .join('&');
            try {
                const { data } = await authApi.paymentAnalytic(
                    queryString || ''
                );
                setPaymentAnalytic(data.totalPaid);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchMerchantCom = async () => {
            for (const merchant of merchants.current) {
                try {
                    const { data } = await authApi.orderAnalytic(
                        `campId=${merchant}&${queryString || ''}`
                    );
                    setMerchantCom((prev) => ({
                        ...prev,
                        [merchant]: data.totalCommission,
                    }));
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchOrderAnalytic();
        fetchPaymentAnalytic();
        fetchMerchantCom();
    }, [filter]);

    return (
        <div className="p-8 w-full gap-5">
            <div className="p-4">
                <span>Thời gian</span>
                <DatePicker.RangePicker
                    onChange={onSelectDate}
                    placeholder={['Từ ngày', 'Đến ngày']}
                />
            </div>
            <div className="flex justify-center gap-2 w-full text-xl">
                <div className="p-3 bg-white rounded-md shadow-md border">
                    <p>Thống kê doanh số</p>
                    <div>
                        <div className="flex gap-4 justify-between">
                            <span>Tổng hoa hồng:</span>
                            <span>
                                {formatMoney.format(
                                    orderAnalytic.totalCommission
                                )}
                            </span>
                        </div>
                        <div className="flex gap-2 justify-between">
                            <span>Hoa hồng User:</span>
                            <span>
                                {formatMoney.format(
                                    orderAnalytic.totalUserCommission || 0
                                )}
                            </span>
                        </div>
                        <div className="flex gap-2 justify-between">
                            <span>Đã chuyển khoản:</span>
                            <span>
                                {formatMoney.format(paymentAnalytic || 0)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="p-3 bg-white rounded-md shadow-md border">
                    <p>Thống kê từng sàn</p>
                    <div className="flex justify-between gap-4">
                        <span>Tổng hoa hồng:</span>
                        <span>
                            {formatMoney.format(
                                orderAnalytic.totalCommission || 0
                            )}
                        </span>
                    </div>
                    <div className="flex justify-between gap-2">
                        <span>TikTok:</span>
                        <span>
                            {formatMoney.format(merchantCom.tiktok || 0)}
                        </span>
                    </div>
                    <div className="flex justify-between gap-2">
                        <span>Lazada:</span>
                        <span>
                            {formatMoney.format(merchantCom.lazada || 0)}
                        </span>
                    </div>
                    <div className="flex justify-between gap-2">
                        <span>Shopee:</span>
                        <span>
                            {formatMoney.format(merchantCom.shopee || 0)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashBoard;
