import axiosClient from './axiosClient';
export type LiveSessionType = {
    id: number;
    sessionId: string;
    title: string;
    status: number;
};
const shopeeLiveApi = {
    getSessionV2(query: string | undefined) {
        const baseUrl = `shopeelive/live_session_v2?${query || ''}`;
        return axiosClient.get<{
            status: string;
            liveSessions: LiveSessionType[];
        }>(baseUrl);
    },
    likeSessionProducts(sessionId: string | number) {
        const baseUrl = 'shopeelive/like_session_products';
        return axiosClient.post(baseUrl, {
            sessionId,
        });
    },
    goliveV2(sessionId: string | number) {
        const baseUrl = 'shopeelive/go_live_v2';
        return axiosClient.post(baseUrl, {
            sessionId,
        });
    },
    offLive() {
        const baseUrl = 'shopeelive/off_live_v2';
        return axiosClient.get(baseUrl);
    },
};

export default shopeeLiveApi;
