import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

const SuccessPopup: FC<{
    successMsg?: string;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ successMsg, setModal }) => {
    return (
        <div className="animate-zoomIn w-4/5 md:w-2/3 lg:w-2/5 aspect-square flex flex-col justify-between items-center bg-white rounded-lg shadow-sm border gap-2 p-2">
            <div className="flex flex-col  justify-center items-center flex-1">
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-5xl text-pink-500"
                />
                <p className="p-4 text-center">{successMsg || 'SUCCESS!!!'}</p>
            </div>
            <div className="w-full">
                <button
                    onClick={() => setModal(false)}
                    className="p-2 border w-full rounded-lg"
                >
                    Đóng
                </button>
            </div>
        </div>
    );
};

export default SuccessPopup;
